import React from 'react';
import BrandLogo from './brand-logo';
import './footer.scss';
import { useTranslation } from 'react-i18next';
import LocalizedLink from './localized-link';
import Icon from './icon';
import Image from './image';
import { Link } from 'gatsby';

const Footer = () => {
  const { t } = useTranslation('Footer');
  return (
    <footer className="st-footer">
      <div className="st-footer__main">
        <div className="container">
          <div className="st-footer__columns">
            <div className="st-footer__brand">
              <BrandLogo className="st-footer__logo" />

              <p className="st-footer__brand-info">
                Herengracht 420 - 1017 BZ Amsterdam
                <br />
                The Netherlands
              </p>

              <div className="st-footer__social-media">
                <a href="https://www.instagram.com/stampdutyfree/">
                  <Icon name="instagram" size={1.2} />
                </a>
                <a href="https://www.facebook.com/stampdutyfree">
                  <Icon name="facebook" size={1.2} />
                </a>
                <a href="https://twitter.com/stampdutyfree">
                  <Icon name="twitter" size={1.2} />
                </a>
                <a href="https://www.linkedin.com/company/stampdutyfree/">
                  <Icon name="linkedin" size={1.2} />
                </a>
              </div>
            </div>

            <div className="st-footer__links">
              <div className="st-footer__column">
                <p className="title is-6 is-marginless has-text-white">{t('label.product')}</p>
                <ul className="st-footer__list">
                  <li>
                    <LocalizedLink to="/" className="st-footer__link">
                      {t('link.forTravelers')}
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/business" className="st-footer__link">
                      {t('link.forBusiness')}
                    </LocalizedLink>
                  </li>
                  <li>
                    <LocalizedLink to="/duty-free-district" className="st-footer__link">
                      {t('link.dutyFreeDistrict')}
                    </LocalizedLink>
                  </li>
                </ul>
              </div>

              <div className="st-footer__column">
                <p className="title is-6 is-marginless has-text-white">{t('label.company')}</p>
                <ul className="st-footer__list">
                  <li>
                    <LocalizedLink to="/about" className="st-footer__link">
                      {t('link.aboutUs')}
                    </LocalizedLink>
                  </li>
                  <li>
                    <a
                      href="https://angel.co/company/stamp-5/jobs"
                      target="_blank"
                      className="st-footer__link"
                    >
                      {t('link.jobs')}
                    </a>
                  </li>
                </ul>
              </div>

              <div className="st-footer__column">
                <p className="title is-6 is-marginless has-text-white">{t('label.information')}</p>
                <ul className="st-footer__list">
                  <li>
                    <a href="mailto:help@stampit.co" className="st-footer__link">
                      {t('link.contactUs')}
                    </a>
                  </li>
                  <li>
                    <Link to="/privacy-policy" className="st-footer__link">
                      {t('link.privacyPolicy')}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="st-footer__more">
        <div className="container">
          <div className="st-footer__integrations">
            <p className="st-footer__integrations-label">{t('integrations.label')}</p>
            <div className="st-footer__integrations-logo">
              <Image
                filename="third-party-logos/agenzia-dogane-monopoli--white.png"
                alt="Agenzia delle Entrate"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
