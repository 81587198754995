import React, { useContext } from 'react';
import Icon from './icon';

import { languages, languagesCodes } from '../config/languages';

import { useTranslation } from 'react-i18next';
import { AlternateLinksContext } from './wrapWithI18nProvider';
import { Link } from 'gatsby';

import { getLocalizedPath } from '../utils/i18n-utils';

const LanguageSwitcher = () => {
  const alternateLinks = useContext(AlternateLinksContext);
  const { i18n } = useTranslation();
  const currentLanguageCode = i18n.language;
  const currentLanguage = languages[currentLanguageCode];
  const languageCodesWithoutCurrent = languagesCodes.filter(l => l !== currentLanguageCode);

  const saveLanguage = lang => {
    if (typeof window !== 'undefined') {
      window.localStorage.setItem('userLang', lang);
    }
  };

  return (
    <div className="dropdown is-hoverable is-right">
      <div className="dropdown-trigger" aria-haspopup="true" aria-controls="language-dropdown">
        <div className="button is-white">
          <span>
            <Icon name="globe" />
          </span>
          <span style={{ marginLeft: '.75em' }}>{currentLanguage.labelShort}</span>
          <Icon name="chevron-down" />
        </div>
      </div>
      <div className="dropdown-menu" id="language-dropdown" role="menu">
        <div className="dropdown-content">
          {languageCodesWithoutCurrent.map(code => (
            <Link
              to={
                alternateLinks
                  ? alternateLinks.find(l => l.langCode === code).path
                  : getLocalizedPath('/', code)
              }
              className="dropdown-item"
              onClick={() => saveLanguage(code)}
              replace
              key={code}
            >
              {languages[code].label}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LanguageSwitcher;
